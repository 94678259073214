import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div class="PageContact">
      <div class="Container">
        <div class="pv4 pv5-ns ph35 ph4-ns">
          <div class="flex-ns">
            <div class="PageContact_Intro w-50-ns pr5-ns">
              <div class="PageContact_Header">
                Ready when you are
              </div>
              <div class="PageContact_Sub measure white-80">
                Tell us about you and your project, and we'll contact you right away with our next steps.
                <br />
                <br />
                <a class="Button" href="mailto:info@polydara.com">Send us an email</a>
              </div>
              <div class="PageContact_Header">
                Visit us
              </div>
              <div class="PageContact_Address white-80">
                Polydara Technology Group, Inc.
                <br />
                79 EDSA, Mandaluyong City
                <br />
                Metro Manila, PH
                <br />
                1552
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
